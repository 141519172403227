.apv {
	@apply inline-flex  text-center justify-center items-center text-xs bg-danger/5;
}

.apb {
	@apply mb-1;
}

.apb * {
	@apply static;
}
