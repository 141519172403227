.attachment-photo {
	@apply w-full;
}

.attachment-video {
	@apply w-full;
}

.attachment-video .play-wrapper {
	@apply z-[2] w-full h-full absolute flex justify-center items-center;
}

.attachment-video .player {
	@apply mx-auto relative !rounded;
}

.attachment-video .player video,
.attachment-video .player iframe,
.attachment-video .player figure {
	@apply w-full h-full !rounded bg-black;
}

.attachment-video .cover img {
	@apply object-contain h-full w-full;
}

.attachment-video .player.platform-vk img {
	@apply !object-cover max-h-full;
}

.attachment-video .play {
	@apply absolute w-20 stroke-white drop-shadow-[0_0_10px_black] stroke-2;
}

.attachment-video .play path:nth-child(2) {
	@apply fill-white;
}

.attachment-video .info {
	@apply absolute right-4 bottom-4 bg-gray-100 text-xs px-4 py-2 rounded opacity-60;
}
.photo-grid {
	@apply flex w-full max-h-80;
}

.photo-grid > div {
	@apply flex;
}

.photo-grid .photo-container {
	@apply inline-block cursor-pointer grow p-[2px];
}

.photo-grid .counter {
	@apply absolute w-full h-full flex justify-center items-center text-3xl text-white bg-gray-400 bg-opacity-30 top-0;
}

.photo-grid img {
	@apply w-full h-full object-cover;
}

.attachment-link {
	@apply border-[1px] !items-start justify-between rounded-lg overflow-hidden w-full bg-gray-100 bg-opacity-50 dark:bg-gray-800;
}

.attachment-link h2 {
	@apply font-bold text-base;
}

.attachment-vacancy {
	@apply text-primary shadow-[0_0_0_1px] rounded-lg overflow-hidden;
}

.attachment-vacancy .mark {
	@apply uppercase text-xs bg-primary py-1 px-4 text-white mb-0;
}

.attachment-vacancy .body {
	@apply p-4 text-black space-y-1;
}

.attachment-vacancy h2 {
	@apply font-bold text-xl mb-0;
}

.inner > * {
	@apply overflow-hidden text-ellipsis;
}

.inner {
	@apply overflow-hidden after:absolute after:w-full after:h-16 after:bottom-0 after:left-0 after:bg-gradient-to-t after:from-white dark:after:from-dark;
}

.attachment-html {
	@apply w-full;
}

.attachment-html a {
	@apply text-primary underline hover:text-green-600;
}

.attachment-html ol {
	@apply list-decimal pl-6 mb-4;
}

.attachment-html ul {
	@apply list-disc pl-6 mb-4;
}

.attachment-html .fotorama {
	@apply flex flex-col items-center gap-2 my-4;
}

.attachment-html .fotorama img,
.attachment-html img[width='100%'],
.attachment-html img[alt='Image'] {
	@apply w-auto !h-auto object-contain max-w-full rounded m-auto hover:cursor-pointer mb-2;
}

.attachment-html iframe {
	@apply !w-full aspect-video;
}

.fslightbox-container {
	@apply !z-[1100];
}

.react-player__preview {
	@apply rounded;
}

.attachment-article {
	@apply border rounded-lg;
}
