.post-news .title {
	@apply font-bold text-xl mb-4;
}

.post-news h2 {
	@apply mt-4;
}

.post-news img {
	@apply max-h-[50vh] object-contain bg-gray-100;
}

article.post-news-full a {
	@apply text-primary underline underline-offset-1;
}

article.post-news-full a:hover {
	@apply text-green-500;
}

article.post-news-full blockquote,
.post-full blockquote {
	@apply md:text-lg px-3 md:px-4 mb-6 border-l-[4px] border-gray-300 text-gray-600 dark:border-gray-600 dark:text-gray-400;
}

article.post-news-full blockquote p:last-child,
.post-full blockquote p:last-child {
	@apply !m-0;
}
